.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-family: 'TT Norms Pro', sans-serif;
  display: inline-block;
  color: var(--s-btn-color);
  cursor: pointer;
  padding: 4px 17px;
  appearance: none;
  border-radius: 1000px;
  border: 2px solid var(--s-btn-border);
  box-sizing: border-box;
  font-size: 20px;
  line-height: 1.25;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  background-color: var(--s-btn-bg-hover);
  color: var(--s-btn-color-hover);
}
@media (max-width: 1023px) {
  .button {
    font-size: 16px;
    line-height: 1.5625;
  }
}
@media (max-width: 767px) {
  .button {
    font-size: 11px;
    line-height: 2.27272727;
    padding: 0 10px;
  }
}
.donateButton {
  background-color: #ADC008;
  color: #FFFFFF;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.013em;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 1000px;
  transform: rotate(-10deg);
  padding: 13px 13px 30px;
  position: relative;
  font-weight: bold;
}
.donateButton:after {
  content: '';
  position: absolute;
  bottom: 23px;
  width: 14px;
  height: 14px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-heart-white.svg);
}
@media (max-width: 1023px) {
  .donateButton {
    font-size: 15px;
    line-height: 1.13333333;
    padding: 12px 12px 20px;
  }
}
@media (max-width: 767px) {
  .donateButton {
    font-size: 11px;
    line-height: 1.18181818;
    padding: 8px 8px 20px;
  }
  .donateButton:after {
    width: 11px;
    height: 11px;
    bottom: 20px;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.02em;
  font-weight: bold;
  transition: all 0.4s;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  display: flex;
  gap: 0 35px;
  transition: all 0.4s;
}
@media (max-width: 1250px) {
  div.sub1 {
    gap: 0 10px;
  }
}
div.sub1 > .item {
  position: relative;
}
div.sub1 > .item > .menu {
  --s-linkcolor: #000000;
  --s-linkcolorhover: #FFFFFF;
  text-transform: uppercase;
  position: relative;
  isolation: isolate;
  padding: 12px 0;
  white-space: no-wrap;
}
div.sub1 > .item > .menu:before {
  content: '';
  position: absolute;
  left: -10px;
  top: 11px;
  width: 0;
  height: 30px;
  background-color: #ADC008;
  z-index: -1;
  transition: all 0.4s;
}
div.sub1 > .item > .menu.path {
  --s-linkcolor: #FFFFFF;
}
div.sub1 > .item > .menu.path:before {
  width: calc(100% + 20px);
}
div.sub1 > .item:hover .menu,
div.sub1 > .item:focus .menu {
  --s-linkcolor: #FFFFFF;
}
div.sub1 > .item:hover .menu:before,
div.sub1 > .item:focus .menu:before {
  width: calc(100% + 20px);
}
div.sub1 > .item:hover div.sub2,
div.sub1 > .item:focus div.sub2 {
  pointer-events: all;
  height: var(--js-elementHeight);
  padding: 16px;
}
div.sub1 > .item:hover div.sub2 > .item,
div.sub1 > .item:focus div.sub2 > .item {
  opacity: 1;
}
div.sub2 {
  z-index: 2000;
  width: auto;
  min-width: 143px;
  position: absolute;
  top: 100%;
  left: -10px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: #ADC008;
  height: 0;
  overflow: hidden;
  transition: all 0.4s;
}
div.sub2 > .item {
  opacity: 0;
  transition: all 0.4s;
}
div.sub2 > .item > .menu {
  --s-linkcolor: #FFFFFF;
  --s-linkcolorhover: #000000;
}
.navigation div.sub1 > .item.exit div.sub2 {
  right: 0;
  left: unset;
}
#home {
  height: 60px;
}
@media (max-width: 1200px) {
  #home {
    height: 40px;
  }
}
.section--header {
  padding: 45px 0;
}
.section--multimood {
  height: 430px;
}
.container--pageTitle {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.footpart--one {
  grid-column: 2 / span 6;
}
.footpart--two {
  grid-column: 9 / span 3;
  margin-left: 30px;
}
.toplink {
  width: 49px;
  height: 49px;
}
.footservices {
  margin-top: 20px;
}
.area--one .unitTwo--imageLeft .unit__content {
  margin-left: -200px;
}
.area--one .unitTwo .unit__content {
  width: calc(100% + 200px);
  margin-top: 130px;
  padding: 45px 60px;
}
.area--one .unitTwo .part.pict:first-child {
  width: 125px;
}
.area--one .unitTwo .part.text:nth-child(2) {
  width: calc(100% - 200px);
}
.area--one .unitThree .unit__background {
  height: 286px;
}
.area--one .unitThree .part.pict:first-child {
  height: 286px;
}
.area--one .unitThree .part.text:nth-child(2) {
  padding: 10px;
}
.area--one .unitFour {
  padding: 45px 0;
}
.area--one .unitFour .fold-toggle:before {
  width: 49px;
  height: 49px;
}
#view .area--one .unitFour .fold-toggle {
  width: 53px;
  height: 53px;
}
.area--one .unitFive {
  grid-column: 2 / span 10;
  padding: 0 100px 30px 100px;
}
.area--one .unitFive .less .part.pict:first-child {
  width: calc(100% + 200px);
  margin-left: -100px;
  height: 210px;
}
.area--one .unitFive .fold-toggle:before {
  width: 49px;
  height: 49px;
}
#view .area--one .unitFive .fold-toggle {
  width: 53px;
  height: 53px;
}
.area--one .unitSix .unit__background {
  height: 286px;
}
.area--one .unitSix .part.pict:first-child {
  height: 286px;
}
.area--one .unitSix .part.pict:first-child .cb-image-caption {
  padding: 60px;
}
.area--one .unitSix .part.text:nth-child(2) {
  padding: 10px;
}
.part--donateButton {
  width: 144px;
  height: 144px;
}
.part--pageTitleIcon {
  width: 75px;
  height: 75px;
}
/*# sourceMappingURL=./screen-large.css.map */